import React, { useState } from 'react';
import './Profile.css'; 
const Profile = () => {
  const [user, setUser] = useState({
    name: 'John Doe',
    email: 'johndoe@example.com',
    phone: '+1234567890',
    bio: 'Software Developer with a passion for technology and innovation.',
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({ ...user });

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    setUser(editedUser);
    setIsEditing(false);
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <img src={'https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1728864000&semt=ais_hybrid-rr-similar'} alt="Profile" className="profile-picture" />
        <h1>{isEditing ? 'Edit Profile' : 'Profile'}</h1>
        <button onClick={handleEditToggle}>
          {isEditing ? 'Cancel' : 'Edit'}
        </button>
      </div>
      <div className="profile-details">
        <div className="detail-item">
          <label>Name:</label>
          {isEditing ? (
            <input
              type="text"
              name="name"
              value={editedUser.name}
              onChange={handleEditChange}
            />
          ) : (
            <span>{user.name}</span>
          )}
        </div>
        <div className="detail-item">
          <label>Email:</label>
          {isEditing ? (
            <input
              type="email"
              name="email"
              value={editedUser.email}
              onChange={handleEditChange}
            />
          ) : (
            <span>{user.email}</span>
          )}
        </div>
        <div className="detail-item">
          <label>Phone:</label>
          {isEditing ? (
            <input
              type="tel"
              name="phone"
              value={editedUser.phone}
              onChange={handleEditChange}
            />
          ) : (
            <span>{user.phone}</span>
          )}
        </div>
        <div className="detail-item">
          <label>Bio:</label>
          {isEditing ? (
            <textarea
              name="bio"
              value={editedUser.bio}
              onChange={handleEditChange}
            />
          ) : (
            <span>{user.bio}</span>
          )}
        </div>
      </div>
      {isEditing && (
        <button className="save-button" onClick={handleSave}>
          Save Changes
        </button>
      )}
    </div>
  );
};

export default Profile;
