import React from 'react';

const Admin = () => {
  return (
    <div className="admin-dashboard">
      <h1>Welcome to the Admin Dashboard</h1>

      {/* Stats Section */}
      <div className="stats">
        <div>Total Users: 1024</div>
        <div>Jobs Posted: 50</div>
        <div>Events Listed: 12</div>
      </div>

      {/* Action Buttons */}
      <div className="actions">
        <button className="btn btn-primary">Create Forum</button>
        <button className="btn btn-success">Create Event</button>
        <button className="btn btn-info">Create Group</button>
      </div>

      {/* Recent Activities Section */}
      <div className="recent-activities">
        <h3>Recent Activities</h3>
        {/* Display a list of recent activities */}
        <ul>
          <li>New user registered: John Doe</li>
          <li>Job posted: Software Developer at Tech Corp</li>
          <li>New event added: AI Conference 2024</li>
        </ul>
      </div>

      {/* Upcoming Events Section */}
      <div className="upcoming-events">
        <h3>Upcoming Events</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Event Name</th>
              <th>Date</th>
              <th>Location</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>AI Conference 2024</td>
              <td>March 12, 2024</td>
              <td>New York, NY</td>
              <td>
                <button className="btn btn-sm btn-warning">Edit</button>
                <button className="btn btn-sm btn-danger">Delete</button>
              </td>
            </tr>
            <tr>
              <td>Blockchain Workshop</td>
              <td>April 25, 2024</td>
              <td>San Francisco, CA</td>
              <td>
                <button className="btn btn-sm btn-warning">Edit</button>
                <button className="btn btn-sm btn-danger">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Admin;
