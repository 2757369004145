import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // FullCalendar package for React
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

const EventsPage = () => {
  const [events, setEvents] = useState([
    { id: 1, title: 'Machine Learning Conference', date: '2024-11-15', type: 'Conference', location: 'New York, USA', description: 'A major conference on the latest trends in machine learning.' },
    { id: 2, title: 'AI Ethics Workshop', date: '2024-09-18', type: 'Workshop', location: 'London, UK', description: 'Discuss ethical issues surrounding AI development in research.' },
    { id: 3, title: 'Blockchain Research Symposium', date: '2024-12-05', type: 'Symposium', location: 'Berlin, Germany', description: 'Exploring blockchain applications in academic research.' }
  ]);

  const [newEvent, setNewEvent] = useState({
    title: '',
    date: '',
    type: 'Conference',
    location: '',
    description: ''
  });

  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (info) => {
    const clickedEvent = events.find(event => event.title === info.event.title);
    setSelectedEvent(clickedEvent);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };

  const handleNewEvent = (e) => {
    e.preventDefault();
    const newEventEntry = { ...newEvent, id: events.length + 1 };
    setEvents([...events, newEventEntry]);
    setNewEvent({ title: '', date: '', type: 'Conference', location: '', description: '' });
  };

  return (
    <div className="events-page">
      <h2>Academic Conferences, Workshops, and Events</h2>
      <p>Stay up to date with upcoming academic events or manage your own events.</p>

      {/* Calendar View */}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events.map(event => ({
          title: event.title,
          start: event.date
        }))}
        eventClick={handleEventClick}
      />

      {/* Event Details Modal */}
      {selectedEvent && (
        <div className="event-modal">
          <h3>{selectedEvent.title}</h3>
          <p><strong>Date:</strong> {selectedEvent.date}</p>
          <p><strong>Type:</strong> {selectedEvent.type}</p>
          <p><strong>Location:</strong> {selectedEvent.location}</p>
          <p>{selectedEvent.description}</p>
          <button onClick={() => setSelectedEvent(null)}>Close</button>
        </div>
      )}

      {/* Event Listings */}
      <ul className="event-listings">
        {events.map(event => (
          <li key={event.id}>
            <h3>{event.title} <span>({event.type})</span></h3>
            <p><strong>Date:</strong> {event.date}</p>
            <p><strong>Location:</strong> {event.location}</p>
            <button onClick={() => setSelectedEvent(event)}>View Details</button>
          </li>
        ))}
      </ul>

      {/* Admin Form for Adding Events */}
      
    </div>
  );
};

export default EventsPage;
