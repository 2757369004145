import React, { useState } from 'react';

const JobsInternships = () => {
  const [listings, setListings] = useState([
    { id: 1, title: 'Data Scientist', company: 'Tech Solutions', location: 'New York, USA', type: 'Job' },
    { id: 2, title: 'Research Intern', company: 'AI Labs', location: 'San Francisco, USA', type: 'Internship' },
    { id: 3, title: 'Backend Developer', company: 'OpenSource Inc.', location: 'Remote', type: 'Job' },
    { id: 4, title: 'Marketing Intern', company: 'InnovateX', location: 'London, UK', type: 'Internship' }
  ]);

  const [newListing, setNewListing] = useState({
    title: '',
    company: '',
    location: '',
    type: 'Job'
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewListing({ ...newListing, [name]: value });
  };

  const handleNewListing = (e) => {
    e.preventDefault();
    const newJob = {
      id: listings.length + 1,
      ...newListing
    };
    setListings([...listings, newJob]);
    setNewListing({ title: '', company: '', location: '', type: 'Job' });
  };

  return (
    <div className="jobs-internships">
      <h2>Job and Internship Postings</h2>
      <p>Explore job and internship opportunities or post a new one.</p>

      {/* Filter Options */}
      <div className="filter-options">
        <button>All</button>
        <button>Jobs</button>
        <button>Internships</button>
      </div>

      {/* Listings */}
      <ul>
        {listings.map((listing) => (
          <li key={listing.id}>
            <h3>{listing.title} <span>({listing.type})</span></h3>
            <p>{listing.company} - {listing.location}</p>
          </li>
        ))}
      </ul>

      {/* Post New Job/Internship */}
      
    </div>
  );
};

export default JobsInternships;
