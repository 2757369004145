import logo from './logo.svg';
import './App.css';
import Header from './Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Register from './Register';
import Contact from './Contact';
import About from './About';
import Groups from './Groups';
import Forums from './Forums';
import Networking from './Networking';
import JobsInternships from './JobsInternships';
import EventsPage from './EventsPage';
import Profile from './Profile';
import Admin from './Admin';
import AdminHeader from './AdminHeader';
import CreateEventsPage from './CreateEventsPage';
import PostJob from './PostJob';
import Settings from './Settings';
import Logout from './Logout';
import Student from './Student';
import AccountSettings from './AccountSettings';

function App() {
  const role = localStorage.getItem('role');

  return (
    <Router>
            {role === 'admin' ? <AdminHeader /> : <Header />}

    {/* <Header /> */}
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/aboutus" element={<About />} />
      <Route path="/groups" element={<Groups />} />
      <Route path="/forums" element={<Forums />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/networking" element={<Networking />} />
      <Route path="/opportunities" element={<JobsInternships />} />
      <Route path="/events" element={<EventsPage />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/createevent" element={<CreateEventsPage />} />
      <Route path="/postjob" element={<PostJob />} />
      <Route path="/student" element={<Student />} />
      <Route path="/settings" element={<AccountSettings />} />
      <Route path='/logout' element={<Logout/>}/>
    </Routes>
  </Router>
  );
}

export default App;
