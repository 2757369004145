import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="home">
      <section className="hero">
        <h1>Empowering Academic Collaboration</h1>
        <p>Join our platform to explore opportunities, connect with experts, and advance your academic career.</p>
        <button className="get-started-btn">Get Started</button>
      </section>

      <section className="features">
        <div className="feature">
          <h2>Networking</h2>
          <p>Engage with academics and researchers across the globe. Join interest groups and collaborate on projects.</p>
          <Link to="/networking">Learn More</Link>
        </div>

        <div className="feature">
          <h2>Career Development</h2>
          <p>Access exclusive resources and mentorship programs that will guide your academic and professional journey.</p>
          <Link to="/resources">Explore Resources</Link>
        </div>

        <div className="feature">
          <h2>Academic Events</h2>
          <p>Stay updated on academic events including conferences, workshops, and seminars tailored for your growth.</p>
          <Link to="/events">View Events</Link>
        </div>
      </section>

      <section className="testimonials">
        <h2>What Our Members Say</h2>

        <div className="testimonial">
          <p>"The platform has been instrumental in helping me connect with mentors who provided great insights for my research."</p>
          <h3>Dr. Sarah Johnson</h3>
          <p>Researcher, Harvard University</p>
        </div>

        <div className="testimonial">
          <p>"I found multiple internship opportunities and was able to collaborate with professors from different universities."</p>
          <h3>John Doe</h3>
          <p>Graduate Student, Stanford University</p>
        </div>

        <div className="testimonial">
          <p>"Being part of this network has opened doors for career development and research collaborations."</p>
          <h3>Prof. Michael Lee</h3>
          <p>Professor, MIT</p>
        </div>
      </section>
      <footer>
        <p>&copy; 2024 Academic Networking Site. All Rights Reserved.</p>
    </footer>
    </div>
  );
};

export default Home;
