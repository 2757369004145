import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // FullCalendar package for React
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

const CreateEventsPage = () => {
  const [events, setEvents] = useState([
    { id: 1, title: 'Machine Learning Conference', date: '2024-11-15', type: 'Conference', location: 'New York, USA', description: 'A major conference on the latest trends in machine learning.' },
    { id: 2, title: 'AI Ethics Workshop', date: '2024-09-18', type: 'Workshop', location: 'London, UK', description: 'Discuss ethical issues surrounding AI development in research.' },
    { id: 3, title: 'Blockchain Research Symposium', date: '2024-12-05', type: 'Symposium', location: 'Berlin, Germany', description: 'Exploring blockchain applications in academic research.' }
  ]);

  const [newEvent, setNewEvent] = useState({
    title: '',
    date: '',
    type: 'Conference',
    location: '',
    description: ''
  });

  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (info) => {
    const clickedEvent = events.find(event => event.title === info.event.title);
    setSelectedEvent(clickedEvent);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };

  const handleNewEvent = (e) => {
    e.preventDefault();
    const newEventEntry = { ...newEvent, id: events.length + 1 };
    setEvents([...events, newEventEntry]);
    setNewEvent({ title: '', date: '', type: 'Conference', location: '', description: '' });
  };
return (
  <div className="events-page">
  
  <form onSubmit={handleNewEvent} className="new-event-form">
        <h3>Add a New Event</h3>
        <input
          type="text"
          name="title"
          placeholder="Event Title"
          value={newEvent.title}
          onChange={handleInputChange}
          required
        />
        <input
          type="date"
          name="date"
          value={newEvent.date}
          onChange={handleInputChange}
          required
        />
        <select name="type" value={newEvent.type} onChange={handleInputChange}>
          <option value="Conference">Conference</option>
          <option value="Workshop">Workshop</option>
          <option value="Symposium">Symposium</option>
        </select>
        <input
          type="text"
          name="location"
          placeholder="Location"
          value={newEvent.location}
          onChange={handleInputChange}
          required
        />
        <textarea
          name="description"
          placeholder="Event Description"
          value={newEvent.description}
          onChange={handleInputChange}
          required
        ></textarea>
        <button type="submit">Add Event</button>
      </form>
      </div>
      );
      };

      export default CreateEventsPage;
      