import React from 'react';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';

const Student = () => {
  return (
    <div className="student-dashboard">
      <h1>Welcome to the Student Dashboard</h1>

      {/* Navigation Links */}
      <div className="navigation">
      <NavLink to="/settings" activeClassName="active-link">Account Settings</NavLink>

        <button className="btn btn-secondary">Account Settings</button>
        <button className="btn btn-secondary">Career Development</button>
        <button className="btn btn-secondary">Forums</button>
        <button className="btn btn-secondary">Mentorship</button>
      </div>

      {/* Jobs Section */}
      <div className="jobs">
        <h3>Available Job Listings</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Job Title</th>
              <th>Company</th>
              <th>Location</th>
              <th>Posted Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Software Developer</td>
              <td>Tech Corp</td>
              <td>Remote</td>
              <td>October 1, 2024</td>
              <td>
                <button className="btn btn-sm btn-primary">Apply</button>
              </td>
            </tr>
            <tr>
              <td>Data Analyst</td>
              <td>Data Solutions Inc.</td>
              <td>New York, NY</td>
              <td>October 5, 2024</td>
              <td>
                <button className="btn btn-sm btn-primary">Apply</button>
              </td>
            </tr>
            <tr>
              <td>Web Developer Intern</td>
              <td>Innovatech</td>
              <td>San Francisco, CA</td>
              <td>October 10, 2024</td>
              <td>
                <button className="btn btn-sm btn-primary">Apply</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Student;
