import React, { useState } from 'react';

const Forums = () => {
  const [discussions, setDiscussions] = useState([
    { id: 1, topic: 'Best practices for research writing', posts: 12, description: 'Discuss tips and strategies for writing high-impact academic papers.' },
    { id: 2, topic: 'How to apply for academic conferences?', posts: 8, description: 'Share your experiences and ask questions about the application process for academic conferences.' },
    { id: 3, topic: 'AI ethics in modern research', posts: 15, description: 'Debate the ethical considerations of AI development in academic research.' }
  ]);

  const [newDiscussion, setNewDiscussion] = useState({ topic: '', description: '' });

  const handleNewDiscussion = (e) => {
    e.preventDefault();
    const newTopic = {
      id: discussions.length + 1,
      topic: newDiscussion.topic,
      description: newDiscussion.description,
      posts: 0
    };
    setDiscussions([...discussions, newTopic]);
    setNewDiscussion({ topic: '', description: '' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDiscussion({ ...newDiscussion, [name]: value });
  };

  return (
    <div className="forums">
      <h2>Public Forums</h2>
      <p>Participate in academic discussions or start a new topic in our forums.</p>
      <ul>
        {discussions.map(discussion => (
          <li key={discussion.id}>
            <h3>{discussion.topic}</h3>
            <p>{discussion.description}</p>
            <p><strong>{discussion.posts}</strong> posts</p>
          </li>
        ))}
      </ul>

      <form onSubmit={handleNewDiscussion}>
        <h3>Start a new discussion</h3>
        <input 
          type="text" 
          name="topic" 
          placeholder="Enter discussion topic" 
          value={newDiscussion.topic} 
          onChange={handleInputChange} 
          required 
        />
        <textarea 
          name="description" 
          placeholder="Describe your discussion topic" 
          value={newDiscussion.description} 
          onChange={handleInputChange} 
          required 
        ></textarea>
        <button type="submit">Start Discussion</button>
      </form>
    </div>
  );
};

export default Forums;
