// AccountSettings.js
import React, { useState } from 'react';

const AccountSettings = () => {
  const [profile, setProfile] = useState({ name: '', email: '', phone: '' });
  const [password, setPassword] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });

  // Handle input changes for forms
  const handleProfileChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const handleProfileSubmit = (e) => {
    e.preventDefault();
    // Submit profile updates
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    // Submit password change
  };

  return (
    <div className="account-settings">
      <h2>Account Settings</h2>
      
      {/* Profile Details */}
      <form onSubmit={handleProfileSubmit}>
        <h3>Profile Details</h3>
        <label>Name:</label>
        <input type="text" name="name" value={profile.name} onChange={handleProfileChange} />
        <label>Email:</label>
        <input type="email" name="email" value={profile.email} onChange={handleProfileChange} />
        <label>Phone:</label>
        <input type="tel" name="phone" value={profile.phone} onChange={handleProfileChange} />
        <button type="submit">Update Profile</button>
      </form>

      {/* Password Change */}
      <form onSubmit={handlePasswordSubmit}>
        <h3>Change Password</h3>
        <label>Old Password:</label>
        <input type="password" name="oldPassword" value={password.oldPassword} onChange={handlePasswordChange} />
        <label>New Password:</label>
        <input type="password" name="newPassword" value={password.newPassword} onChange={handlePasswordChange} />
        <label>Confirm Password:</label>
        <input type="password" name="confirmPassword" value={password.confirmPassword} onChange={handlePasswordChange} />
        <button type="submit">Change Password</button>
      </form>

      {/* Account Management */}
      <div className="account-management">
        <h3>Account Management</h3>
        <button className="delete-account">Delete Account</button>
        <button className="two-factor-auth">Manage Two-Factor Authentication</button>
      </div>
    </div>
  );
};

export default AccountSettings;
