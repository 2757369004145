import React, { useState } from 'react';

const PostJob = () => {
  const [listings, setListings] = useState([
    { id: 1, title: 'Data Scientist', company: 'Tech Solutions', location: 'New York, USA', type: 'Job' },
    { id: 2, title: 'Research Intern', company: 'AI Labs', location: 'San Francisco, USA', type: 'Internship' },
    { id: 3, title: 'Backend Developer', company: 'OpenSource Inc.', location: 'Remote', type: 'Job' },
    { id: 4, title: 'Marketing Intern', company: 'InnovateX', location: 'London, UK', type: 'Internship' }
  ]);

  const [newListing, setNewListing] = useState({
    title: '',
    company: '',
    location: '',
    type: 'Job'
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewListing({ ...newListing, [name]: value });
  };

  const handleNewListing = (e) => {
    e.preventDefault();
    const newJob = {
      id: listings.length + 1,
      ...newListing
    };
    setListings([...listings, newJob]);
    setNewListing({ title: '', company: '', location: '', type: 'Job' });
  };
return(
  <div>
    <form onSubmit={handleNewListing} className="new-listing-form">
      <h3>Post a New Job or Internship</h3>
      <input 
        type="text" 
        name="title" 
        placeholder="Job/Internship Title" 
        value={newListing.title} 
        onChange={handleInputChange} 
        required 
      />
      <input 
        type="text" 
        name="company" 
        placeholder="Company Name" 
        value={newListing.company} 
        onChange={handleInputChange} 
        required 
      />
      <input 
        type="text" 
        name="location" 
        placeholder="Location" 
        value={newListing.location} 
        onChange={handleInputChange} 
        required 
      />
      <select name="type" value={newListing.type} onChange={handleInputChange}>
        <option value="Job">Job</option>
        <option value="Internship">Internship</option>
      </select>
      <button type="submit">Post</button>
    </form>
  </div>
);
};

export default PostJob;
